import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {UtilsHelper} from '../helpers/utils.helper';


export class CustomValidators {
  public static requiredIfKeyNotEmpty(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheckHasValue = !UtilsHelper.IsNilOrEmpty(parent.get(key).value);
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (keyToCheckHasValue && !currentControlHasValue) {
        return { required: parent.get(key).value };
      }
      return null;
    }
  }

  public static requiredIfKeyEmpty(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheckIsEmpty = UtilsHelper.IsNilOrEmpty(parent.get(key).value);
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (keyToCheckIsEmpty && !currentControlHasValue) {
        return { required: parent.get(key).value };
      }
      return null;
    }
  }

  public static requiredIfKeyTrue(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheck = parent.get(key);
      if (!keyToCheck) {
        return null;
      }

      const keyToCheckValue = keyToCheck.value;
      const currentControlHasValue = control.value;
      if (keyToCheckValue === true && !currentControlHasValue) {
        return { required: true };
      }

      return null;
    };
  }

  public static requiredIfAnyKeysEmpty(keys: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const someKeysToCheckAreEmpty = keys.some((key) => UtilsHelper.IsNilOrEmpty(parent.get(key).value))
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (someKeysToCheckAreEmpty && !currentControlHasValue) {
        return { required: keys };
      }
      return null;
    }
  }
}
